<div class="azd-modal azd-onBoardingModal" *ngIf="participantForm">
    <form [formGroup]="participantForm">
        <img src="assets/images/logoAZ-world-of-event-L.png" alt="Allianz World of Events" role="none" class="azd-modal__logo" />
        <ng-container *ngIf="currentStep === constantsFE.ONBOARDING_MODAL_STEP_TICKETS">
            <div class="azd-modal__content" nxModalContent>
                <h3 nxHeadline="subsection-large" class="azd-modal__contentModalTitle nx-margin-bottom-2m">
                    {{ userData.name + ' ' + userData.surname }}
                </h3>
                <div nxLayout="grid">
                    <div nxRow>
                        <div nxCol="12">
                            <nx-label class="nx-margin-top-s nx-margin-right-s nx-margin-bottom-s">
                                <span nxCopytext="normal" class="nx-font-weight-bold"> Quanti biglietti desideri richiedere? </span>
                            </nx-label>
                            <nx-formfield label="Numero biglietti *">
                                <nx-dropdown formControlName="requested_tickets">
                                    <nx-dropdown-item [value]="number" *ngFor="let number of numberOptions">
                                        {{ number }}
                                    </nx-dropdown-item>
                                </nx-dropdown>
                                <nx-error nxFormfieldError> Numero biglietti obbligatorio. </nx-error>
                            </nx-formfield>
                        </div>
                    </div>
                </div>
            </div>
            <div class="azd-modal__footer" nxModalActions>
                <button nxButton="secondary" type="button" (click)="onSetCurrentStepClick()">Annulla</button>
                <button
                    nxButton="primary"
                    type="button"
                    [disabled]="participantForm.get('requested_tickets')?.invalid"
                    (click)="onSetCurrentStepClick(constantsFE.ONBOARDING_MODAL_STEP_PRIVACY)"
                >
                    continua
                </button>
            </div>
        </ng-container>
        <ng-container *ngIf="currentStep === constantsFE.ONBOARDING_MODAL_STEP_PRIVACY">
            <div class="azd-modal__content" nxModalContent>
                <h3 nxHeadline="subsection-large" class="azd-modal__contentModalTitle nx-margin-bottom-2m">
                    {{ userData.name + ' ' + userData.surname }}
                </h3>

                <div nxLayout="grid">
                    <div nxRow>
                        <div nxCol="12" class="azd-modal__contentPrivacyTitle">
                            <nx-label>
                                <span nxCopytext="normal" class="nx-font-weight-bold">Informativa privacy *</span>
                            </nx-label>
                            <nx-link class="nx-margin-top-s">
                                <a [routerLink]="null" nxCopytext="medium" (click)="onOpenFileClick()">
                                    <nx-icon name="file-text" class="nx-margin-right-xs"></nx-icon>
                                    Leggi di più
                                </a>
                            </nx-link>
                        </div>
                    </div>
                    <div nxRow>
                        <div nxCol="12">
                            <div class="azd-element-in-a-row">
                                <nx-checkbox class="nx-margin-top-s" formControlName="is_base_privacy_signed" name="is_base_privacy_signed">
                                    Accetto il trattamento dei dati
                                    <span *ngIf="participantForm.get('requested_tickets')?.value >= 2">per me e i miei accompagnatori</span>
                                </nx-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="azd-modal__footer" nxModalActions>
                <button nxButton="secondary" type="button" (click)="onSetCurrentStepClick(constantsFE.ONBOARDING_MODAL_STEP_TICKETS)">
                    Indietro
                </button>
                <button
                    nxButton="primary"
                    type="button"
                    [disabled]="participantForm.get('is_base_privacy_signed')?.invalid"
                    (click)="
                        participantAdditionalInformationFormArray.controls.length > 0
                            ? onSetCurrentStepClick(constantsFE.ONBOARDING_MODAL_STEP_ADDITIONAL_INFO)
                            : onOnboardingSubmitClick()
                    "
                >
                    {{
                        participantAdditionalInformationFormArray.controls.length > 0
                            ? 'prosegui'
                            : !event.subscription
                            ? 'invia richiesta'
                            : 'modifica'
                    }}
                </button>
            </div>
        </ng-container>
        <ng-container
            *ngIf="
                participantAdditionalInformationFormArray.controls.length > 0 &&
                currentStep === constantsFE.ONBOARDING_MODAL_STEP_ADDITIONAL_INFO
            "
        >
            <ng-container
                *ngFor="let stepFormArray of participantAdditionalInformationFormArray.controls; index as i; last as last; first as first"
            >
                <ng-container *ngIf="currentAdditionalInformationStepIndex === i">
                    <div class="azd-modal__content" formArrayName="additional_information" nxModalContent>
                        <h3 nxHeadline="subsection-large" class="azd-modal__contentModalTitle nx-margin-bottom-2m">
                            {{ userData.name + ' ' + userData.surname }}
                        </h3>
                        <div nxLayout="grid">
                            <div nxRow *ngFor="let additionalInformationForm of getStepFormArray(stepFormArray).controls">
                                <div nxCol="12">
                                    <azd-additional-information
                                        [isReadonly]="false"
                                        [_additionalInformationForm]="additionalInformationForm"
                                    ></azd-additional-information>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="azd-modal__footer" nxModalActions>
                        <button
                            type="button"
                            nxButton="secondary"
                            (click)="
                                first
                                    ? onSetCurrentStepClick(constantsFE.ONBOARDING_MODAL_STEP_PRIVACY)
                                    : (currentAdditionalInformationStepIndex = i - 1)
                            "
                        >
                            Indietro
                        </button>
                        <button
                            type="button"
                            nxButton="primary"
                            (click)="
                                last
                                    ? companionFormArray.controls.length > 0 && experience.additional_information_for_companion.length > 0
                                        ? onSetCurrentStepClick(constantsFE.ONBOARDING_MODAL_STEP_ADDITIONAL_INFO_COMPANION)
                                        : onOnboardingSubmitClick()
                                    : (currentAdditionalInformationStepIndex = i + 1)
                            "
                            [disabled]="stepFormArray.invalid"
                        >
                            {{ !last || companionFormArray.controls.length > 0 ? 'Prosegui' : 'Conferma' }}
                        </button>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
        <!--STEP ADDITIONAL INFO COMPANION -->
        <ng-container
            *ngIf="companionFormArray.controls.length > 0 && currentStep === constantsFE.ONBOARDING_MODAL_STEP_ADDITIONAL_INFO_COMPANION"
        >
            <ng-container
                *ngFor="
                    let companionForm of companionFormArray.controls;
                    index as companionIndex;
                    last as companionLast;
                    first as companionFirst
                "
                formArrayName="companions"
            >
                <ng-container
                    *ngIf="
                        getCompanionAdditionalInformationFormArray(companionForm).controls.length > 0 &&
                        currentCompanionStepIndex === companionIndex
                    "
                    [formGroupName]="companionIndex"
                >
                    <ng-container
                        formArrayName="additional_information"
                        *ngFor="
                            let stepFormArray of getCompanionAdditionalInformationFormArray(companionForm).controls;
                            index as companionInfoIndex;
                            last as companionInfoLast;
                            first as companionInfoFirst
                        "
                    >
                        <div class="azd-modal__content" nxModalContent>
                            <ng-container *ngIf="companionForm.get('currentAdditionalInformationStepIndex')?.value === companionInfoIndex">
                                <h3 nxHeadline="subsection-large" class="azd-modal__contentModalTitle nx-margin-bottom-2m">
                                    Accompagnatore {{ companionForm.get('companion_index')?.value }}
                                </h3>
                                <div nxLayout="grid">
                                    <div nxRow *ngFor="let additionalInformationForm of getStepFormArray(stepFormArray).controls">
                                        <div nxCol="12">
                                            <azd-additional-information
                                                [isReadonly]="false"
                                                [_additionalInformationForm]="additionalInformationForm"
                                            ></azd-additional-information>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div class="azd-modal__footer" nxModalActions>
                            <button
                                type="button"
                                nxButton="secondary"
                                (click)="
                                    companionFirst && companionInfoFirst
                                        ? onSetCurrentStepClick(constantsFE.ONBOARDING_MODAL_STEP_ADDITIONAL_INFO)
                                        : companionInfoFirst
                                        ? (currentCompanionStepIndex = companionIndex - 1)
                                        : backCompanionAdditionalInformationStep(companionForm)
                                "
                            >
                                Indietro
                            </button>
                            <button
                                type="button"
                                nxButton="primary"
                                (click)="
                                    companionLast && companionInfoLast
                                        ? onOnboardingSubmitClick()
                                        : companionInfoLast
                                        ? (currentCompanionStepIndex = companionIndex + 1)
                                        : nextCompanionAdditionalInformationStep(companionForm)
                                "
                                [disabled]="stepFormArray.invalid"
                            >
                                {{ companionLast && companionInfoLast ? 'Conferma' : 'Prosegui' }}
                            </button>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </form>
</div>
