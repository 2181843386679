<div class="azd-modal azd-shareEventModal">
    <img src="assets/images/logoAZ-world-of-event-L.png" alt="Allianz World of Events" role="none" class="azd-modal__logo" />

    <form [formGroup]="modalForm" autocomplete="off">
        <ng-container *ngIf="currentStep === constantsFE.SHARE_EVENT_MODAL_INVITATIONS">
            <div class="azd-modal__content" nxModalContent>
                <h3 nxHeadline="subsection-large" class="azd-modal__contentModalTitle">Condividi evento digitale</h3>
                <div nxLayout="grid">
                    <div nxRow>
                        <div nxCol="6">
                            <nx-formfield label="Nome *">
                                <input
                                    type="text"
                                    nxInput
                                    name="name"
                                    formControlName="name"
                                    autocomplete="off"
                                    (focusout)="onInputFocusOut()"
                                    (keyup)="onGetCustomerKeyUp('full_name', $event)"
                                />
                                <nx-error nxFormfieldError> Campo Nome obbligatorio. </nx-error>
                            </nx-formfield>
                        </div>
                        <div nxCol="6">
                            <nx-formfield label="Cognome *">
                                <input
                                    type="text"
                                    nxInput
                                    name="surname"
                                    formControlName="surname"
                                    autocomplete="off"
                                    (focusout)="onInputFocusOut()"
                                    (keyup)="onGetCustomerKeyUp('full_name', $event)"
                                />
                                <nx-error nxFormfieldError> Campo Cognome obbligatorio. </nx-error>
                            </nx-formfield>
                        </div>
                        <div nxCol="12">
                            <div class="azd-autocomplete" [class.--show]="showPanel === 'full_name'">
                                <div
                                    *ngFor="let customer of customers"
                                    class="azd-autocomplete__option"
                                    (click)="onSetCustomerClick(customer)"
                                >
                                    <span class="azd-autocomplete__optionName">
                                        {{ customer.name + ' ' + customer.surname }}
                                    </span>
                                    <span class="azd-autocomplete__optionType"></span>
                                    <span class="azd-autocomplete__optionBirthDate">
                                        {{ customer.birth_date ? (customer.birth_date | dateParser).format('DD/MM/YYYY') : '' }}
                                    </span>
                                    <span class="azd-autocomplete__optionFiscalCode">
                                        {{ customer.fiscal_code }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div nxRow>
                        <div nxCol="12">
                            <nx-formfield label="Codice fiscale *">
                                <input
                                    type="text"
                                    nxInput
                                    name="fiscal_code"
                                    formControlName="fiscal_code"
                                    autocomplete="off"
                                    (focusout)="onInputFocusOut()"
                                    (keyup)="onGetCustomerKeyUp('fiscal_code', $event)"
                                />
                                <nx-error nxFormfieldError> Campo codice fiscale obbligatorio. </nx-error>
                            </nx-formfield>
                        </div>
                        <div nxCol="12">
                            <div class="azd-autocomplete" [class.--show]="showPanel === 'fiscal_code'">
                                <div
                                    *ngFor="let customer of customers"
                                    class="azd-autocomplete__option"
                                    (click)="onSetCustomerClick(customer)"
                                >
                                    <span class="azd-autocomplete__optionName">
                                        {{ customer.name + ' ' + customer.surname }}
                                    </span>
                                    <span class="azd-autocomplete__optionType"></span>
                                    <span class="azd-autocomplete__optionBirthDate">
                                        {{ customer.birth_date ? (customer.birth_date | dateParser).format('DD/MM/YYYY') : '' }}
                                    </span>
                                    <span class="azd-autocomplete__optionFiscalCode">
                                        {{ customer.fiscal_code }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div nxRow *ngIf="isAZCandidacy && subscriberAgencies.length">
                        <div nxCol="12">
                            <nx-formfield label="Seleziona agenzia a cui farà riferimento l'invitato *">
                                <nx-dropdown formControlName="az_agency_id">
                                    <nx-dropdown-item *ngFor="let agency of subscriberAgencies" [value]="agency.id">
                                        {{ agency!.business_name + ' - ' + agency.azw_company!.prefix + agency!.external_code | translate }}
                                    </nx-dropdown-item>
                                </nx-dropdown>
                                <nx-error nxFormfieldError> L'agenzia è obbligatoria. </nx-error>
                            </nx-formfield>
                        </div>
                    </div>
                    <div nxRow>
                        <div nxCol="12">
                            <nx-formfield label="Indirizzo mail">
                                <input type="email" nxInput name="email" formControlName="email" />
                            </nx-formfield>
                        </div>
                    </div>
                    <div nxRow *ngIf="selectedCustomer">
                        <div nxCol="12">
                            <nx-formfield label="Altro indirizzo mail">
                                <input
                                    type="email"
                                    nxInput
                                    name="alternative_email"
                                    (focusin)="displayAlternativeEmailHint = true"
                                    (focusout)="displayAlternativeEmailHint = false"
                                    formControlName="alternative_email"
                                />
                                <span nxFormfieldHint *ngIf="displayAlternativeEmailHint && modalForm.get('alternative_email')!.value">
                                    (se inserito, l'invito verrà inviato solamento a questo indirizzo mail)
                                </span>
                                <nx-error nxFormfieldError *ngIf="modalForm.get('alternative_email')!.hasError('validEmail')">
                                    Indirizzo email non valido
                                </nx-error>
                            </nx-formfield>
                        </div>
                    </div>
                    <div nxRow>
                        <div nxCol="12">
                            <button
                                type="button"
                                nxButton="secondary"
                                [disabled]="
                                    modalForm.invalid ||
                                    loading ||
                                    (!modalForm.get('email')!.value && !modalForm.get('alternative_email')!.value) ||
                                    modalForm.untouched
                                "
                                (click)="onAddInvitationClick()"
                            >
                                Aggiungi destinatario
                            </button>
                        </div>
                    </div>
                    <div nxRow *ngIf="!canInvite">
                        <div nxCol="12">
                            <nx-error *ngIf="isPresentOnList()">
                                Questo <b>codice fiscale è già presente</b> nella lista sottostante dei
                                <b>tuoi invitati di questo evento</b>
                            </nx-error>
                            <nx-error *ngIf="!isPresentOnList()">
                                Questo <b>codice fiscale è già presente</b> tra i partecipanti di <b>questo evento</b>, perchè
                                <b>invitato da un altro Agente/Consulente Finanziario.</b>
                            </nx-error>
                        </div>
                    </div>
                    <div nxRow class="nx-margin-y-s">
                        <div nxCol="12">
                            <hr />
                        </div>
                    </div>
                    <div nxRow *ngIf="invitations.length !== 0">
                        <div nxCol="12">
                            <div class="azd-invitations">
                                <div
                                    class="azd-invitations__invitation"
                                    *ngFor="let invitation of invitations; let i = index; let last = last"
                                >
                                    <div class="azd-invitations__invitationInfo">
                                        <b>{{ invitation.name + ' ' + invitation.surname }}</b>
                                        <span>{{ invitation.alternative_email || invitation.email }}</span>
                                    </div>
                                    <nx-icon name="trash" (click)="onRemoveInvitationClick(i)"></nx-icon>
                                    <hr *ngIf="!last" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="azd-modal__footer" nxModalActions>
                <button nxButton="secondary" type="button" nxModalClose>Indietro</button>
                <button
                    nxButton="primary"
                    type="button"
                    [disabled]="this.invitations.length === 0 || loading"
                    (click)="onSendInvitationsClick()"
                >
                    Invia invito
                </button>
            </div>
        </ng-container>
    </form>
</div>
