<div class="azd-modal azd-experienceRegistrationModal" *ngIf="participantForm">
    <form [formGroup]="participantForm">
        <img src="assets/images/logoAZ-world-of-event-L.png" alt="Allianz World of Events" role="none" class="azd-modal__logo" />
        <ng-container *ngIf="currentStep === constantsFE.EXPERIENCE_REGISTRATION_MODAL_STEP_PARTICIPATION">
            <div class="azd-modal__content" nxModalContent>
                <h3 nxHeadline="subsection-large" class="azd-modal__contentModalTitle nx-margin-bottom-2m">
                    {{ userData.name + ' ' + userData.surname }}
                </h3>
                <div nxLayout="grid">
                    <div nxRow>
                        <div nxCol="12">
                            <div [class.azd-element-in-a-row]="!isTravelEvent || !isAgent">
                                <nx-label class="nx-margin-top-s nx-margin-right-s">
                                    <span nxCopytext="normal" class="nx-font-weight-bold"> Parteciperai all'evento? </span>
                                </nx-label>
                                <nx-radio-group
                                    name="is_participating"
                                    formControlName="is_participating"
                                    *ngIf="!isTravelEvent || !isAgent"
                                >
                                    <div class="azd-horizontal nx-margin-top-s">
                                        <nx-radio
                                            [value]="true"
                                            labelSize="small"
                                            [class.nx-margin-right-xs]="!isTravelEvent || !isAgent"
                                            [disabled]="
                                                eventStatusById[event.status_id].tag === constantsFE.EVENT_STATUSES_LIVE_FULL_CAPACITY
                                            "
                                        >
                                            Parteciperò
                                        </nx-radio>
                                        <nx-radio [value]="false" labelSize="small"> Non parteciperò </nx-radio>
                                    </div>
                                    <nx-error appearance="text"> È obbligatorio rispondere a questa domanda prima di procedere. </nx-error>
                                </nx-radio-group>
                                <nx-radio-group
                                    name="companionship_status_id"
                                    formControlName="companionship_status_id"
                                    *ngIf="isTravelEvent && isAgent"
                                >
                                    <nx-radio
                                        *ngFor="let companionshipStatus of travelCompanionshipStatuses"
                                        [value]="companionshipStatus.id"
                                        labelSize="small"
                                        class="nx-margin-top-s"
                                        [disabled]="eventStatusById[event.status_id].tag === constantsFE.EVENT_STATUSES_LIVE_FULL_CAPACITY"
                                    >
                                        {{
                                            'CONSTANTS.' +
                                                constantsFE.EVENT_SUBSCRIPTION_SUBSCRIBER_COMPANIONSHIP_STATUSES +
                                                '.' +
                                                companionshipStatus.tag | translate
                                        }}
                                    </nx-radio>
                                    <nx-error appearance="text"> È obbligatorio rispondere a questa domanda prima di procedere. </nx-error>
                                </nx-radio-group>
                            </div>
                        </div>
                    </div>
                    <div nxRow *ngIf="isTravelEvent && isAgent">
                        <div nxCol="12">
                            <p>
                                L'eventuale accompagnatore potrà essere aggiunto esclusivamente dal proprio portale Allianz Wold of Events
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="azd-modal__footer" nxModalActions>
                <button nxButton="secondary" type="button" (click)="onSetCurrentStepClick()">Annulla</button>
                <button
                    nxButton="primary"
                    type="button"
                    [disabled]="
                        participantForm.value.is_participating === null ||
                        (eventStatusById[event.status_id].tag === constantsFE.EVENT_STATUSES_LIVE_FULL_CAPACITY &&
                            participantForm.value.is_participating) ||
                        disableContinueButton
                    "
                    (click)="nextStepAfterParticipationAnswer()"
                >
                    continua
                </button>
            </div>
        </ng-container>

        <ng-container *ngIf="currentStep === constantsFE.EXPERIENCE_REGISTRATION_MODAL_STEP_EXPERIENCES">
            <div class="azd-modal__content" nxModalContent>
                <h3 nxHeadline="subsection-large" class="azd-modal__contentModalTitle nx-margin-bottom-2m">
                    {{ userData.name + ' ' + userData.surname }}
                </h3>
                <div nxLayout="grid">
                    <div nxRow>
                        <div nxCol="12">
                            <nx-label class="nx-margin-top-s nx-margin-right-s">
                                <span nxCopytext="normal" class="nx-font-weight-bold"> A quale experience desideri partecipare? </span>
                            </nx-label>
                            <nx-radio-group name="experience_id" formControlName="experience_id" (groupValueChange)="setExperience()">
                                <nx-radio
                                    [value]="experience.id"
                                    labelSize="small"
                                    class="nx-margin-top-s"
                                    [class.nx-margin-right-xs]="!last"
                                    *ngFor="let experience of event.experiences; let last = last"
                                >
                                    {{ experience.short_name }}
                                </nx-radio>
                                <nx-error appearance="text"> È obbligatorio rispondere a questa domanda prima di procedere. </nx-error>
                            </nx-radio-group>
                        </div>
                    </div>
                </div>
            </div>
            <div class="azd-modal__footer" nxModalActions>
                <button
                    nxButton="secondary"
                    type="button"
                    (click)="
                        onSetCurrentStepClick(
                            isAgent || isNetworkEvent ? constantsFE.EXPERIENCE_REGISTRATION_MODAL_STEP_PARTICIPATION : undefined
                        )
                    "
                >
                    {{ isAgent || isNetworkEvent ? 'Indietro' : 'Annulla' }}
                </button>
                <button
                    nxButton="primary"
                    type="button"
                    [disabled]="participantForm.value.experience_id === null"
                    (click)="
                        (isTravelEvent || agencies.length > 1) && isAgent
                            ? onSetCurrentStepClick(constantsFE.EXPERIENCE_REGISTRATION_MODAL_STEP_PRIVACY)
                            : participantForm.value.is_participating || !isTravelEvent
                              ? onSetCurrentStepClick(constantsFE.EXPERIENCE_REGISTRATION_MODAL_STEP_PRIVACY_INTERNAL)
                              : participantAdditionalInformationFormArray.controls.length > 0 &&
                                  (participantForm.value.is_participating || !isTravelEvent)
                                ? onSetCurrentStepClick(constantsFE.EXPERIENCE_REGISTRATION_MODAL_STEP_ADDITIONAL_INFO)
                                : onExperienceRegistrationClick()
                    "
                >
                    continua
                </button>
            </div>
        </ng-container>

        <ng-container *ngIf="currentStep === constantsFE.EXPERIENCE_REGISTRATION_MODAL_STEP_PRIVACY">
            <div class="azd-modal__content" nxModalContent>
                <h3 nxHeadline="subsection-large" class="azd-modal__contentModalTitle nx-margin-bottom-2m">
                    {{ userData.name + ' ' + userData.surname }}
                </h3>
                <div nxLayout="grid">
                    <div
                        nxRow
                        *ngIf="
                            userData.role_id !== undefined &&
                            roleById[userData.role_id].tag === constantsFE.USER_ROLES_AZ_AGENT &&
                            agencies.length > 1
                        "
                    >
                        <div nxCol="12">
                            <nx-formfield label="Seleziona l'agenzia a cui farai riferimento *">
                                <nx-dropdown formControlName="agency_id">
                                    <nx-dropdown-item *ngFor="let agency of agencies" [value]="agency.id">
                                        {{ agency!.business_name + ' - ' + agency.azw_company!.prefix + agency!.external_code | translate }}
                                    </nx-dropdown-item>
                                </nx-dropdown>
                                <nx-error nxFormfieldError> L'agenzia è obbligatoria. </nx-error>
                            </nx-formfield>
                        </div>
                    </div>
                    <ng-container *ngIf="isTravelEvent">
                        <div
                            class="azd-attachmentBox__wrapper -travelAttachments"
                            *ngIf="hasTravelEventAttachment(constantsFE.EVENT_ATTACHMENT_TYPES_WINNING_DECLARATION)"
                        >
                            <div class="azd-element-in-a-row">
                                <nx-label>
                                    <span nxCopytext="normal" class="nx-font-weight-bold"> Dichiarazione di partecipazione * </span>
                                </nx-label>
                                <nx-link
                                    class="azd-attachmentBox__downloadAttachmentBtn"
                                    (click)="onDownloadAttachmentTemplate(constantsFE.EVENT_ATTACHMENT_TYPES_WINNING_DECLARATION)"
                                >
                                    <span>Scarica modello</span>
                                </nx-link>
                            </div>
                            <div nxRow class="azd-attachmentBox azd-element-in-a-row">
                                <div class="azd-attachmentBox__description">
                                    Carica qui la scansione della dichiarazione di partecipazione firmata
                                </div>
                                <div>
                                    <nx-link *ngIf="!getAttachmentFormValue('winning_declaration.disclaimer.documentName')">
                                        <label for="has_winning_declaration_edit">Carica file</label>
                                    </nx-link>
                                    <span
                                        *ngIf="getAttachmentFormValue('winning_declaration.disclaimer.documentName')"
                                        class="azd-attachmentBox__attachmentFilename"
                                        (click)="getAttachment('winning_declaration.disclaimer')"
                                    >
                                        <nx-icon name="paperclip" size="auto"></nx-icon>
                                        <span [nxTooltip]="getAttachmentFormValue('winning_declaration.disclaimer.documentName')">
                                            {{ getAttachmentFormValue('winning_declaration.disclaimer.documentName') | truncate: ['10'] }}
                                        </span>
                                        <span (click)="removeFile('winning_declaration.disclaimer')">
                                            <nx-icon name="trash" size="s"></nx-icon>
                                        </span>
                                        <span
                                            *ngIf="getAttachmentFormValue('winning_declaration.disclaimer.documentName')"
                                            class="azd-attachmentBox__attachmentSize"
                                        >
                                            {{ getAttachmentFormValue('winning_declaration.disclaimer.documentSize') | bytesToSize }}
                                        </span>
                                    </span>
                                    <input
                                        id="has_winning_declaration_edit"
                                        type="file"
                                        ngFileSelect
                                        [options]="options"
                                        (uploadOutput)="onUploadOutput($event, constantsFE.EVENT_ATTACHMENT_TYPES_WINNING_DECLARATION)"
                                        [uploadInput]="uploadInput"
                                    />
                                </div>
                            </div>
                        </div>

                        <div
                            class="azd-attachmentBox__wrapper -travelAttachments"
                            *ngIf="hasTravelEventAttachment(constantsFE.EVENT_ATTACHMENT_TYPES_PARTIAL_RENOUNCE_DECLARATION)"
                        >
                            <div class="azd-element-in-a-row">
                                <nx-label>
                                    <span nxCopytext="normal" class="nx-font-weight-bold"> Dichiarazione di rinuncia parziale * </span>
                                </nx-label>
                                <nx-link
                                    (click)="onDownloadAttachmentTemplate(constantsFE.EVENT_ATTACHMENT_TYPES_PARTIAL_RENOUNCE_DECLARATION)"
                                >
                                    <span>Scarica modello</span>
                                </nx-link>
                            </div>
                            <div class="azd-attachmentBox azd-element-in-a-row">
                                <div class="azd-attachmentBox__description">
                                    Carica qui la scansione della dichiarazione di rinuncia parziale firmata
                                </div>
                                <div>
                                    <nx-link *ngIf="!getAttachmentFormValue('partial_renounce_declaration.disclaimer.documentName')">
                                        <label for="has_partial_renounce_declaration_edit">Carica file</label>
                                    </nx-link>
                                    <span
                                        *ngIf="getAttachmentFormValue('partial_renounce_declaration.disclaimer.documentName')"
                                        class="azd-attachmentBox__attachmentFilename"
                                        (click)="getAttachment('partial_renounce_declaration.disclaimer')"
                                    >
                                        <nx-icon name="paperclip" size="auto"></nx-icon>
                                        <span [nxTooltip]="getAttachmentFormValue('partial_renounce_declaration.disclaimer.documentName')">
                                            {{
                                                getAttachmentFormValue('partial_renounce_declaration.disclaimer.documentName')
                                                    | truncate: ['10']
                                            }}
                                        </span>
                                        <span (click)="removeFile('partial_renounce_declaration.disclaimer')">
                                            <nx-icon name="trash" size="s"></nx-icon>
                                        </span>
                                        <span
                                            *ngIf="getAttachmentFormValue('partial_renounce_declaration.disclaimer.documentName')"
                                            class="azd-attachmentBox__attachmentSize"
                                        >
                                            {{
                                                getAttachmentFormValue('partial_renounce_declaration.disclaimer.documentSize') | bytesToSize
                                            }}
                                        </span>
                                    </span>
                                    <input
                                        id="has_partial_renounce_declaration_edit"
                                        type="file"
                                        ngFileSelect
                                        [options]="options"
                                        (uploadOutput)="
                                            onUploadOutput($event, constantsFE.EVENT_ATTACHMENT_TYPES_PARTIAL_RENOUNCE_DECLARATION)
                                        "
                                        [uploadInput]="uploadInput"
                                    />
                                </div>
                            </div>
                        </div>

                        <div
                            class="azd-attachmentBox__wrapper -travelAttachments"
                            *ngIf="hasTravelEventAttachment(constantsFE.EVENT_ATTACHMENT_TYPES_PARTIAL_NON_PARTICIPATION_DECLARATION)"
                        >
                            <div class="azd-element-in-a-row">
                                <nx-label>
                                    <span nxCopytext="normal" class="nx-font-weight-bold">
                                        Dichiarazione di non partecipazione parziale *
                                    </span>
                                </nx-label>
                                <nx-link
                                    (click)="
                                        onDownloadAttachmentTemplate(
                                            constantsFE.EVENT_ATTACHMENT_TYPES_PARTIAL_NON_PARTICIPATION_DECLARATION
                                        )
                                    "
                                >
                                    <span>Scarica modello</span>
                                </nx-link>
                            </div>
                            <div class="azd-attachmentBox azd-element-in-a-row">
                                <div class="azd-attachmentBox__description">
                                    Carica qui la scansione della dichiarazione di non partecipazione parziale firmata
                                </div>
                                <div>
                                    <nx-link
                                        *ngIf="!getAttachmentFormValue('partial_non_participation_declaration.disclaimer.documentName')"
                                    >
                                        <label for="has_partial_non_participation_declaration_edit">Carica file</label>
                                    </nx-link>
                                    <span
                                        *ngIf="getAttachmentFormValue('partial_non_participation_declaration.disclaimer.documentName')"
                                        class="azd-attachmentBox__attachmentFilename"
                                        (click)="getAttachment('partial_non_participation_declaration.disclaimer')"
                                    >
                                        <nx-icon name="paperclip" size="auto"></nx-icon>
                                        <span
                                            [nxTooltip]="
                                                getAttachmentFormValue('partial_non_participation_declaration.disclaimer.documentName')
                                            "
                                        >
                                            {{
                                                getAttachmentFormValue('partial_non_participation_declaration.disclaimer.documentName')
                                                    | truncate: ['10']
                                            }}
                                        </span>
                                        <span (click)="removeFile('partial_non_participation_declaration.disclaimer')">
                                            <nx-icon name="trash" size="s"></nx-icon>
                                        </span>
                                        <span
                                            *ngIf="getAttachmentFormValue('partial_non_participation_declaration.disclaimer.documentName')"
                                            class="azd-attachmentBox__attachmentSize"
                                        >
                                            {{
                                                getAttachmentFormValue('partial_non_participation_declaration.disclaimer.documentSize')
                                                    | bytesToSize
                                            }}
                                        </span>
                                    </span>
                                    <input
                                        id="has_partial_non_participation_declaration_edit"
                                        type="file"
                                        ngFileSelect
                                        [options]="options"
                                        (uploadOutput)="
                                            onUploadOutput($event, constantsFE.EVENT_ATTACHMENT_TYPES_PARTIAL_NON_PARTICIPATION_DECLARATION)
                                        "
                                        [uploadInput]="uploadInput"
                                    />
                                </div>
                            </div>
                        </div>

                        <div
                            class="azd-attachmentBox__wrapper -travelAttachments"
                            *ngIf="hasTravelEventAttachment(constantsFE.EVENT_ATTACHMENT_TYPES_TOTAL_RENOUNCE_DECLARATION)"
                        >
                            <div class="azd-element-in-a-row">
                                <nx-label>
                                    <span nxCopytext="normal" class="nx-font-weight-bold"> Dichiarazione di rinuncia totale * </span>
                                </nx-label>
                                <nx-link
                                    (click)="onDownloadAttachmentTemplate(constantsFE.EVENT_ATTACHMENT_TYPES_TOTAL_RENOUNCE_DECLARATION)"
                                >
                                    <span>Scarica modello</span>
                                </nx-link>
                            </div>
                            <div nxRow class="azd-attachmentBox azd-element-in-a-row">
                                <div class="azd-attachmentBox__description">
                                    Carica qui la scansione della dichiarazione di rinuncia totale firmata
                                </div>
                                <div>
                                    <nx-link *ngIf="!getAttachmentFormValue('total_renounce_declaration.disclaimer.documentName')">
                                        <label for="has_total_renounce_declaration_edit">Carica file</label>
                                    </nx-link>
                                    <span
                                        *ngIf="getAttachmentFormValue('total_renounce_declaration.disclaimer.documentName')"
                                        class="azd-attachmentBox__attachmentFilename"
                                        (click)="getAttachment('total_renounce_declaration.disclaimer')"
                                    >
                                        <nx-icon name="paperclip" size="auto"></nx-icon>
                                        <span [nxTooltip]="getAttachmentFormValue('total_renounce_declaration.disclaimer.documentName')">
                                            {{
                                                getAttachmentFormValue('total_renounce_declaration.disclaimer.documentName')
                                                    | truncate: ['10']
                                            }}
                                        </span>
                                        <span (click)="removeFile('total_renounce_declaration.disclaimer')">
                                            <nx-icon name="trash" size="s"></nx-icon>
                                        </span>
                                        <span
                                            *ngIf="getAttachmentFormValue('total_renounce_declaration.disclaimer.documentName')"
                                            class="azd-attachmentBox__attachmentSize"
                                        >
                                            {{ getAttachmentFormValue('total_renounce_declaration.disclaimer.documentSize') | bytesToSize }}
                                        </span>
                                    </span>
                                    <input
                                        id="has_total_renounce_declaration_edit"
                                        type="file"
                                        ngFileSelect
                                        [options]="options"
                                        (uploadOutput)="
                                            onUploadOutput($event, constantsFE.EVENT_ATTACHMENT_TYPES_TOTAL_RENOUNCE_DECLARATION)
                                        "
                                        [uploadInput]="uploadInput"
                                    />
                                </div>
                            </div>
                        </div>

                        <div
                            class="azd-attachmentBox__wrapper -travelAttachments"
                            *ngIf="hasTravelEventAttachment(constantsFE.EVENT_ATTACHMENT_TYPES_NON_PARTICIPATION_DECLARATION)"
                        >
                            <div class="azd-element-in-a-row">
                                <nx-label>
                                    <span nxCopytext="normal" class="nx-font-weight-bold"> Dichiarazione di non partecipazione * </span>
                                </nx-label>
                                <nx-link
                                    (click)="onDownloadAttachmentTemplate(constantsFE.EVENT_ATTACHMENT_TYPES_NON_PARTICIPATION_DECLARATION)"
                                >
                                    <span>Scarica modello</span>
                                </nx-link>
                            </div>
                            <div nxRow class="azd-attachmentBox azd-element-in-a-row">
                                <div class="azd-attachmentBox__description">
                                    Carica qui la scansione della dichiarazione di non partecipazione firmata
                                </div>
                                <div>
                                    <nx-link *ngIf="!getAttachmentFormValue('non_participation_declaration.disclaimer.documentName')">
                                        <label for="has_non_participation_declaration_edit">Carica file</label>
                                    </nx-link>
                                    <span
                                        *ngIf="getAttachmentFormValue('non_participation_declaration.disclaimer.documentName')"
                                        class="azd-attachmentBox__attachmentFilename"
                                        (click)="getAttachment('non_participation_declaration.disclaimer')"
                                    >
                                        <nx-icon name="paperclip" size="auto"></nx-icon>
                                        <span [nxTooltip]="getAttachmentFormValue('non_participation_declaration.disclaimer.documentName')">
                                            {{
                                                getAttachmentFormValue('non_participation_declaration.disclaimer.documentName')
                                                    | truncate: ['10']
                                            }}
                                        </span>
                                        <span (click)="removeFile('non_participation_declaration.disclaimer')">
                                            <nx-icon name="trash" size="s"></nx-icon>
                                        </span>
                                        <span
                                            *ngIf="getAttachmentFormValue('non_participation_declaration.disclaimer.documentName')"
                                            class="azd-attachmentBox__attachmentSize"
                                        >
                                            {{
                                                getAttachmentFormValue('non_participation_declaration.disclaimer.documentSize')
                                                    | bytesToSize
                                            }}
                                        </span>
                                    </span>
                                    <input
                                        id="has_non_participation_declaration_edit"
                                        type="file"
                                        ngFileSelect
                                        [options]="options"
                                        (uploadOutput)="
                                            onUploadOutput($event, constantsFE.EVENT_ATTACHMENT_TYPES_NON_PARTICIPATION_DECLARATION)
                                        "
                                        [uploadInput]="uploadInput"
                                    />
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <!-- <ng-container *ngIf="this.participantForm.value.is_participating || !isTravelEvent">
                        <div nxRow>
                            <div nxCol="12">
                                <nx-label>
                                    <span nxCopytext="normal" class="nx-font-weight-bold">Informativa privacy *</span>
                                </nx-label>
                            </div>
                        </div>
                        <div nxRow>
                            <div nxCol="12">
                                <div class="azd-element-in-a-row">
                                    <nx-checkbox
                                        class="nx-margin-top-s"
                                        formControlName="is_release_form_checked"
                                        name="is_release_form_checked"
                                    >
                                        Accetto il trattamento dei dati personali
                                    </nx-checkbox>
                                    <nx-link class="nx-margin-top-s">
                                        <a [routerLink]="null" nxCopytext="medium" (click)="onOpenFileClick()">
                                            <nx-icon name="file-text" class="nx-margin-right-xs"></nx-icon>
                                            Leggi di più
                                        </a>
                                    </nx-link>
                                </div>
                            </div>
                        </div>
                    </ng-container> -->
                </div>
            </div>
            <div class="azd-modal__footer" nxModalActions>
                <button
                    nxButton="secondary"
                    type="button"
                    (click)="
                        onSetCurrentStepClick(
                            !isFromExperienceCard && (event.experiences.length > 1 || isEditInvitationMode || !experience)
                                ? constantsFE.EXPERIENCE_REGISTRATION_MODAL_STEP_EXPERIENCES
                                : isAgent || isNetworkEvent
                                  ? constantsFE.EXPERIENCE_REGISTRATION_MODAL_STEP_PARTICIPATION
                                  : undefined
                        )
                    "
                >
                    Indietro
                </button>
                <button
                    nxButton="primary"
                    type="button"
                    [disabled]="isRegisterDisabled"
                    (click)="
                        participantForm.value.is_participating || !isTravelEvent
                            ? onSetCurrentStepClick(constantsFE.EXPERIENCE_REGISTRATION_MODAL_STEP_PRIVACY_INTERNAL)
                            : participantAdditionalInformationFormArray.controls.length > 0 &&
                                (participantForm.value.is_participating || !isTravelEvent)
                              ? onSetCurrentStepClick(constantsFE.EXPERIENCE_REGISTRATION_MODAL_STEP_ADDITIONAL_INFO)
                              : onExperienceRegistrationClick()
                    "
                >
                    {{ participantAdditionalInformationFormArray.controls.length > 0 ? 'prosegui' : 'registrati' }}
                </button>
            </div>
        </ng-container>

        <ng-container *ngIf="currentStep === constantsFE.EXPERIENCE_REGISTRATION_MODAL_STEP_PRIVACY_INTERNAL">
            <div class="azd-modal__content" nxModalContent>
                <h3 nxHeadline="subsection-large" class="azd-modal__contentModalTitle nx-margin-bottom-2m">
                    {{ userData.name + ' ' + userData.surname }}
                </h3>
                <div nxLayout="grid">
                    <div nxRow>
                        <div nxCol="12" class="azd-modal__contentPrivacyTitle">
                            <nx-label>
                                <span nxCopytext="normal" class="nx-font-weight-bold">Informativa privacy</span>
                            </nx-label>
                            <nx-link>
                                <a [routerLink]="null" nxCopytext="medium" (click)="onOpenFileClick()">
                                    <nx-icon name="file-text"></nx-icon>
                                    leggi informativa
                                </a>
                            </nx-link>
                        </div>
                    </div>
                    <div nxRow class="azw-candidacyModal__input" formGroupName="privacy">
                        <div nxCol="12">
                            <nx-checkbox class="nx-margin-top-s" formControlName="is_base_privacy_signed" *ngIf="event.needs_base_privacy">
                                Ho preso visione dell’informativa sul trattamento dei dati personali *
                            </nx-checkbox>
                            <nx-checkbox
                                class="nx-margin-top-s"
                                formControlName="is_health_privacy_signed"
                                *ngIf="event.needs_health_privacy"
                            >
                                Acconsento al trattamento dei dati sanitari (intolleranze e allergie alimentari) richiesti ai fini
                                dell’evento, come specificato nell’informativa scaricabile *
                            </nx-checkbox>
                            <nx-checkbox
                                class="nx-margin-top-s"
                                formControlName="is_image_privacy_signed"
                                *ngIf="event.needs_image_privacy"
                            >
                                Acconsento al trattamento della mia immagine ai fini dell’evento come specificato nell’informativa
                                scaricabile
                            </nx-checkbox>
                        </div>
                    </div>
                    <div nxRow>
                        <div nxCol="12">
                            <p>( * ) obbligatorio per proseguire</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="azd-modal__footer" nxModalActions>
                <button
                    nxButton="secondary"
                    type="button"
                    (click)="
                        onSetCurrentStepClick(
                            (isTravelEvent || agencies.length > 1) && isAgent
                                ? constantsFE.EXPERIENCE_REGISTRATION_MODAL_STEP_PRIVACY
                                : !isFromExperienceCard && (event.experiences.length > 1 || isEditInvitationMode || !experience)
                                  ? constantsFE.EXPERIENCE_REGISTRATION_MODAL_STEP_EXPERIENCES
                                  : isAgent || isNetworkEvent
                                    ? constantsFE.EXPERIENCE_REGISTRATION_MODAL_STEP_PARTICIPATION
                                    : undefined
                        )
                    "
                >
                    Indietro
                </button>
                <button
                    nxButton="primary"
                    type="button"
                    [disabled]="isRegisterDisabled || participantForm.get('privacy')?.invalid"
                    (click)="
                        participantAdditionalInformationFormArray.controls.length > 0 &&
                        (participantForm.value.is_participating || !isTravelEvent)
                            ? onSetCurrentStepClick(constantsFE.EXPERIENCE_REGISTRATION_MODAL_STEP_ADDITIONAL_INFO)
                            : onExperienceRegistrationClick()
                    "
                >
                    {{ participantAdditionalInformationFormArray.controls.length > 0 ? 'prosegui' : 'registrati' }}
                </button>
            </div>
        </ng-container>

        <ng-container
            *ngIf="
                participantAdditionalInformationFormArray.controls.length > 0 &&
                currentStep === constantsFE.EXPERIENCE_REGISTRATION_MODAL_STEP_ADDITIONAL_INFO
            "
        >
            <ng-container
                *ngFor="let stepFormArray of participantAdditionalInformationFormArray.controls; index as i; last as last; first as first"
            >
                <ng-container *ngIf="currentAdditionalInformationStepIndex === i">
                    <div class="azd-modal__content" formArrayName="additional_information" nxModalContent>
                        <h3 nxHeadline="subsection-large" class="azd-modal__contentModalTitle nx-margin-bottom-2m">
                            {{ userData.name + ' ' + userData.surname }}
                        </h3>
                        <div nxLayout="grid">
                            <div nxRow *ngFor="let additionalInformationForm of getStepFormArray(stepFormArray).controls">
                                <div nxCol="12">
                                    <azd-additional-information
                                        [isReadonly]="false"
                                        [_additionalInformationForm]="additionalInformationForm"
                                    ></azd-additional-information>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="azd-modal__footer" nxModalActions>
                        <button
                            type="button"
                            nxButton="secondary"
                            (click)="
                                first
                                    ? onSetCurrentStepClick(
                                          participantForm.value.is_participating || !isTravelEvent
                                              ? constantsFE.EXPERIENCE_REGISTRATION_MODAL_STEP_PRIVACY_INTERNAL
                                              : (isTravelEvent || agencies.length > 1) && isAgent
                                                ? constantsFE.EXPERIENCE_REGISTRATION_MODAL_STEP_PRIVACY
                                                : !isFromExperienceCard &&
                                                    (event.experiences.length > 1 || isEditInvitationMode || !experience)
                                                  ? constantsFE.EXPERIENCE_REGISTRATION_MODAL_STEP_EXPERIENCES
                                                  : isAgent || isNetworkEvent
                                                    ? constantsFE.EXPERIENCE_REGISTRATION_MODAL_STEP_PARTICIPATION
                                                    : undefined
                                      )
                                    : (currentAdditionalInformationStepIndex = i - 1)
                            "
                        >
                            Indietro
                        </button>
                        <button
                            type="button"
                            nxButton="primary"
                            (click)="last ? onExperienceRegistrationClick() : (currentAdditionalInformationStepIndex = i + 1)"
                            [disabled]="!last ? stepFormArray.invalid : stepFormArray.invalid || isRegisterDisabled"
                        >
                            {{ !last ? 'Prosegui' : 'Conferma' }}
                        </button>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </form>
</div>
