import { NdbxIconModule } from '@allianz/ngx-ndbx/icon';
import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';

import { APP_BASE_HREF, PlatformLocation, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeIT from '@angular/common/locales/it';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppSettingService } from '@app/core/services/base/app-setting.service';

import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { CoreModule } from '@app/core/core.module';
import { SharedModule } from '@app/shared/shared.module';

import { CalendarDateFormatter, CalendarModule, CalendarMomentDateFormatter, DateAdapter, MOMENT } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as dayjs from 'dayjs';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

registerLocaleData(localeIT);

export function dayjsAdapterFactory(): DateAdapter {
    return adapterFactory(dayjs);
}

const appInitializer = (appConfig: AppSettingService) => {
    return (): any => {
        return appConfig.loadAppSetting();
    };
};

export function HttpLoaderFactory(http: HttpClient): any {
    return new MultiTranslateHttpLoader(http, [{ prefix: './assets/i18n/portal_text_strings-', suffix: '.json' }]);
}

export class MultiTranslateHttpLoader implements TranslateLoader {
    constructor(
        private http: HttpClient,
        public resources: { prefix: string; suffix: string }[] = [
            {
                prefix: '/assets/i18n/',
                suffix: '.json',
            },
        ],
    ) {}

    /**
     * Gets the translations from the server
     * @ param lang
     * @ returns {any}
     */
    public getTranslation(lang: string): any {
        return forkJoin(
            this.resources.map((config: any) => {
                return this.http.get(`${config.prefix}${lang}${config.suffix}`);
            }),
        ).pipe(
            map((response: Object[]) => {
                return response.reduce((a: Object, b: Object) => {
                    return Object.assign(a, b);
                });
            }),
        );
    }
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        CalendarModule.forRoot(
            {
                provide: DateAdapter,
                useFactory: dayjsAdapterFactory,
            },
            {
                dateFormatter: {
                    provide: CalendarDateFormatter,
                    useClass: CalendarMomentDateFormatter,
                },
            },
        ),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        LoggerModule.forRoot({
            serverLoggingUrl: 'log_fe_exception',
            level: NgxLoggerLevel.OFF,
            serverLogLevel: NgxLoggerLevel.OFF,
        }),
        CoreModule,
        SharedModule,
        NdbxIconModule,
        AppRoutingModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializer,
            deps: [AppSettingService],
            multi: true,
        },
        {
            provide: APP_BASE_HREF,
            useFactory: (s: PlatformLocation): any => s.getBaseHrefFromDOM(),
            deps: [PlatformLocation],
        },
        {
            provide: MOMENT,
            useValue: dayjs,
        },
        Title,
        TranslatePipe,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
