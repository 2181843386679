<div class="azd-additional-information" [formGroup]="additionalInformationForm">
    <h3 nxHeadline="subsection-small">
        {{ additionalInformationForm.get('front_office_question')?.value
        }}{{ additionalInformationForm.get('is_mandatory')?.value ? ' *' : '' }}
    </h3>
    <!-- TYPE: RADIO -->
    <div
        class="azd-additional-information__input -radio"
        *ngIf="additionalInfoTypeById[additionalInformationForm.get('type_id')?.value].tag === constantsFE.QUESTION_TYPES_RADIO_BUTTON"
    >
        <nx-radio-group formControlName="value">
            <nx-error appearance="text" *ngIf="additionalInformationForm.get('value')?.invalid">
                È obbligatorio rispondere a questa domanda prima di procedere.
            </nx-error>
            <nx-radio
                [value]="choiceForm.get('id')?.value"
                labelSize="small"
                *ngFor="let choiceForm of additionalInformationChoicesFormArray.controls"
                >{{ choiceForm.get('label')?.value }}
            </nx-radio>
        </nx-radio-group>
    </div>
    <!-- TYPE: INPUT -->
    <div
        class="azd-additional-information__input -input"
        *ngIf="
            [constantsFE.QUESTION_TYPES_OPEN_TEXT, constantsFE.QUESTION_TYPES_NUMERICAL].includes(
                additionalInfoTypeById[additionalInformationForm.get('type_id')?.value].tag
            )
        "
    >
        <nx-formfield>
            <input
                nxInput
                formControlName="value"
                [type]="
                    additionalInfoTypeById[additionalInformationForm.get('type_id')?.value].tag === constantsFE.QUESTION_TYPES_OPEN_TEXT
                        ? 'text'
                        : 'number'
                "
            />
            <nx-error nxFormfieldError *ngIf="additionalInformationForm.get('value')?.invalid">
                È obbligatorio rispondere a questa domanda prima di procedere.
            </nx-error>
        </nx-formfield>
    </div>
    <!-- TYPE: DATE -->
    <div
        class="azw-additional-information__input -date"
        *ngIf="additionalInfoTypeById[additionalInformationForm.get('type_id')?.value].tag === constantsFE.QUESTION_TYPES_DATE"
    >
        <nx-formfield>
            <input nxDatefield nxInput [datepicker]="infoDatepicker" formControlName="value" />
            <span nxFormfieldHint>GG/MM/AAAA</span>
            <nx-datepicker-toggle [for]="infoDatepicker" nxFormfieldSuffix> </nx-datepicker-toggle>
            <nx-datepicker #infoDatepicker></nx-datepicker>
            <nx-error nxFormfieldError *ngIf="additionalInformationForm.get('value')?.invalid">
                È obbligatorio rispondere a questa domanda prima di procedere.
            </nx-error>
        </nx-formfield>
    </div>
    <!-- TYPE: DROPDOWN -->
    <div
        class="azd-additional-information__input -dropdown"
        *ngIf="additionalInfoTypeById[additionalInformationForm.get('type_id')?.value].tag === constantsFE.QUESTION_TYPES_DROPDOWN"
    >
        <nx-formfield>
            <nx-dropdown formControlName="value">
                <nx-dropdown-item
                    [value]="choiceForm.get('id')?.value"
                    *ngFor="let choiceForm of additionalInformationChoicesFormArray.controls"
                >
                    {{ choiceForm.get('label')?.value }}
                </nx-dropdown-item>
            </nx-dropdown>
            <nx-error nxFormfieldError *ngIf="additionalInformationForm.get('value')?.invalid">
                È obbligatorio rispondere a questa domanda prima di procedere.
            </nx-error>
        </nx-formfield>
    </div>
    <!-- TYPE: CHECKBOX -->
    <div
        class="azd-additional-information__input -checkbox"
        *ngIf="additionalInfoTypeById[additionalInformationForm.get('type_id')?.value].tag === constantsFE.QUESTION_TYPES_CHECK_BOX"
    >
        <nx-checkbox-group formControlName="value">
            <nx-error appearance="text" *ngIf="additionalInformationForm.get('value')?.invalid">
                È obbligatorio rispondere a questa domanda prima di procedere.
            </nx-error>
            <nx-checkbox [value]="choiceForm.get('id')?.value" *ngFor="let choiceForm of additionalInformationChoicesFormArray.controls">
                {{ choiceForm.get('label')?.value }}
            </nx-checkbox>
        </nx-checkbox-group>
    </div>
    <!-- TYPE: ATTACHMENT -->
    <div
        class="azd-additional-information__input -attachment"
        *ngIf="additionalInfoTypeById[additionalInformationForm.get('type_id')?.value].tag === constantsFE.QUESTION_TYPES_FILE"
    >
        <div class="azd-additional-information__attachment-in-row" [formGroup]="attachmentFormGroup">
            <div class="azd-additional-information__attachment-in-rowAttachment">
                <nx-link *ngIf="!attachmentFormGroup.get('name')?.value && !isReadonly">
                    <label [for]="'question-' + additionalInformationForm.get('id')?.value">Carica file</label>
                </nx-link>
                <input
                    [id]="'question-' + additionalInformationForm.get('id')?.value"
                    type="file"
                    ngFileSelect
                    formControlName="document"
                    [options]="options"
                    (uploadOutput)="onUploadOutputAdditionalInfo($event)"
                    [uploadInput]="uploadInput"
                />
                <div class="azd-additional-information__attachment">
                    <span
                        *ngIf="attachmentFormGroup.get('name')?.value"
                        class="azd-additional-information__attachmentFilename"
                        (click)="getAttachment()"
                    >
                        <nx-icon name="paperclip" size="auto"></nx-icon>
                        {{ attachmentFormGroup.get('name')?.value }}
                        <span (click)="removeFile()" *ngIf="!isReadonly">
                            <nx-icon name="trash" size="s"></nx-icon>
                        </span>
                        <span *ngIf="attachmentFormGroup.get('name')?.value" class="azd-additional-information__attachmentSize">
                            {{ attachmentFormGroup.get('size')?.value | bytesToSize }}
                        </span>
                    </span>
                </div>
            </div>
        </div>
        <nx-error nxFormfieldError *ngIf="attachmentFormGroup.get('size')?.value > constantsFE.MAX_FILE_SIZE">
            File troppo grande. Il limite di dimensioni è di 5 MB.
        </nx-error>
    </div>
</div>
<!-- DEPENDENCIES (ONLY FOR MULTIPLE CHOICES INFORMATION) -->
<ng-container
    *ngIf="
        constantsFE.QUESTION_TYPES_MULTIPLE_CHOICES_GROUP.includes(
            additionalInfoTypeById[additionalInformationForm.get('type_id')?.value].tag
        )
    "
>
    <ng-container *ngFor="let choiceForm of additionalInformationChoicesFormArray.controls">
        <ng-container
            *ngIf="
                additionalInformationForm.get('value')?.value &&
                (additionalInformationForm.get('value')?.value === choiceForm.get('id')?.value ||
                    (isArray(additionalInformationForm.get('value')?.value) &&
                        additionalInformationForm.get('value')?.value.includes(choiceForm.get('id')?.value)))
            "
        >
            <azd-additional-information
                [isReadonly]="isReadonly"
                [_additionalInformationForm]="dependencyForm"
                *ngFor="let dependencyForm of getDependenciesFormArrayByChoice(choiceForm).controls"
            ></azd-additional-information>
        </ng-container>
    </ng-container>
</ng-container>
